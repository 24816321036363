import React from 'react'
import { graphql } from 'gatsby';
import { Card as CardComponent } from 'theme-ui'
import { Stack, Main } from '@layout'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import {
  PostHead,
  PostFooter
} from '@widgets/Post';

import "./animation.css"

export const productQuery = graphql`
query allProducts (
  $id: String!
) {
  allMarkdownRemark(limit: 1000, filter: {id: { eq: $id }}) {
    edges {
      node {
        excerpt
        fields {
          slug
        }
        frontmatter {
          heading
          templateKey
          thumbnailSrc
          title
        }
        html
        id
      }
    }
  }
}
`;

const Post = ({pageContext: { siteUrl }, data}) => {
  const node = data.allMarkdownRemark.edges[0].node;
  const post = {
    id: node.id,
    slug: node.fields.slug,
    body: node.excerpt,
    thumbnailSrc: node.frontmatter.thumbnailSrc,
    title: node.frontmatter.title,
    html: node.html
  };
  return (
    <>
      <Seo {...post} siteUrl={siteUrl} />
      <Divider space={3} />
      <div className="animateInDown">
        <PostHead {...post} />
      </div>
      <Divider space={2} />
      <div className="animateInDown">
        <Main style={{ marginBottom: "30px" }}>
          <CardComponent variant="paper">
            <div
              dangerouslySetInnerHTML={{
                __html: post.html,
              }}
            />
            <img
              style={{ width: "100%" }}
              src={post.thumbnailSrc}
              alt={post.thumbnailText || ""}
            />
            <PostFooter />
          </CardComponent>
        </Main>
      </div>
    </>
  )
}

export default Post